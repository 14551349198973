<template>
  <div>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-row class="my-2">
        <v-col cols="9" class="text-right px-0">
          <div class="fn12" style="font-weight: 600">คะแนนของคุณ</div>
        </v-col>
        <v-col cols="1" class="px-0">
          <img
            src="@/assets/BI.png"
            height="24px"
            width="24px"
            align="left"
            justify="left"
            class="mx-2 mt-n1"
          />
        </v-col>
        <v-col cols="2" class="mt-n1" style="color: #1a347f">
          <div class="fn18-weight7">{{ userData.bi_current_point }}</div>
        </v-col>
      </v-row>
      <v-card class="px-0 rounded-lg mb-4">
        <v-img
          class="object-cover my-4"
          width="100%"
          height="100%"
          :src="form.rewardImg"
        />
        <!-- <div
          class="mx-auto pa-4"
          align="center"
          justify="center"
          style="background: #1a347f"
        >
          <img
            class="object-cover my-4"
            width="100%"
            height="100%"
            :src="form.rewardImg"
          />
        </div> -->
      </v-card>
      <v-row class="my-2">
        <v-col cols="5" class="text-caption px-0" style="font-weight: 600">
          <span class="ml-4 fn12">Redeem Points</span>
          <!-- </v-col>
        <v-col cols="1" class="px-0"> -->
          <img
            src="@/assets/BI.png"
            height="24px"
            width="24px"
            align="right"
            justify="right"
            class="mt-n1 mr-1"
          />
        </v-col>
        <v-col cols="2" class="px-0 mt-n1" style="color: #1a347f">
          <span class="fn12-weight7">{{ form.point }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" class="px-0 mt-n1" style="color: #1a347f">
          <span class="fn12-weight7">{{ status }}</span>
        </v-col>
        <v-col
          cols="12"
          class="px-0 mt-n1 ml-4"
          style="color: #1a347f"
          v-if="tracking_number"
        >
          <span class="fn12-weight7">Tracking Number : </span>

          <span class="fn12-weight7">{{ tracking_number }}</span>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <span class="fn20" style="color: #1a347f; font-weight: 600">{{
            form.reward_name
          }}</span>
        </v-col>
        <v-col cols="12" class="py-0">
          <span class="fn12">
            {{ form.description }}
          </span>
        </v-col>
        <v-col cols="12">
          <span
            class="text-decoration-underline fn14"
            style="color: #06204b; font-weight: 600"
            @click="dialog = true"
          >
            Terms & conditions
          </span>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <!-- (isConfirm = true); -->
      <v-btn
        v-show="!isDetail"
        :disabled="!canRedeem"
        @click="dialog = true"
        class="rounded-lg text-center py-4"
        :style="
          canRedeem
            ? ' color: white;background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);font-family: Open Sans;font-style: normal;font-weight: bold;font-size: 16px;'
            : ''
        "
        large
        block
      >
        แลกของรางวัล
      </v-btn>
      <v-dialog
        v-model="dialog"
        persistent
        :overlay-opacity="0.9"
        max-width="375"
        width="375"
      >
        <v-card
          class="rounded-lg pa-4 text-center"
          :height="dialog ? 270.78 : 400"
        >
          <div>
            <v-row>
              <v-col cols="12" class="fn24-weight7 mt-4" style="color: #07204b">
                ใช้ {{ form.point }} คะแนน
              </v-col>
              <v-col cols="12" class="px-0 fn14 mt-4"
                >แลก {{ form.reward_name }}</v-col
              >
              <v-col cols="6" class="pl-0">
                <v-btn
                  width="120"
                  height="57"
                  outlined
                  dark
                  class="font-weight-bold rounded-lg fn16 cancelbut"
                  @click="cancel()"
                >
                  ยกเลิก
                </v-btn>
              </v-col>
              <v-col cols="6" class="pr-0">
                <v-btn
                  width="120"
                  height="57"
                  outlined
                  dark
                  class="rounded-lg fn16-weight7 confirmbut"
                  @click="
                    () => {
                      (isConfirm1 = true), (dialog = false);
                    }
                  "
                >
                  ตกลง
                </v-btn>
              </v-col>
            </v-row>
            <!-- <div
            @click="redemption()"
            class="rounded-lg text-center py-4"
            style="color: white; background: linear-gradient(268.1deg, #07204B 7.54%, #1A347F 100.72%);font-family: Open Sans; font-style: normal; font-weight: bold; font-size: 16px; width 346px; height: 57px;"
          >
            ตกลง
          </div> -->
          </div>

          <!-- <v-row no-gutters class="mt-14">
          <v-col cols="6" class="pl-0">
            <v-btn
              width="120"
              height="57"
              outlined
              dark
              class="font-weight-bold rounded-lg fn16"
              style="
                background: linear-gradient(
                  268.1deg,
                  #a5a5a5 7.54%,
                  #9f9f9f 100.72%
                );
              "
              @click="cancel"
            >
              ยกเลิก
            </v-btn>
          </v-col>
          <v-col cols="6" class="pr-0">
            <v-btn
              width="120"
              height="57"
              outlined
              dark
              class="rounded-lg fn16-weight7"
              style="
                background: linear-gradient(
                  268.1deg,
                  #07204b 7.54%,
                  #1a347f 100.72%
                );
              "
              @click="submit()"
            >
              ตกลง
            </v-btn>
          </v-col>
        </v-row> -->
        </v-card>
      </v-dialog>
      <v-dialog v-model="isConfirm1">
        <v-card>
          <v-row style="background: #fff" class="py-4">
            <v-col cols="12" class="pa-0">
              <div
                class="text-center mt-4 mb-0"
                style="font-weight: 700; font-size: 32px"
              >
                แลกของรางวัลสำเร็จ!
              </div>
            </v-col>
            <v-col cols="12" class="pb-0">
              <div class="text-center fn16 mb-0" style="font-weight: 600">
                กรุณาระบุที่อยู่สำหรับจัดส่ง
              </div>
            </v-col>
            <v-col cols="12">
              <v-img :src="form.rewardImg" />
            </v-col>
            <v-col cols="12" class="text-center pt-0">
              <span class="fn12" style="font-weight: 600">{{
                form.reward_name
              }}</span>
            </v-col>
          </v-row>
          <v-form ref="form" v-model="valid" class="fn16 pa-2">
            <v-row style="color: #1a347f" class="mt-8">
              <v-col cols="12">
                <div class="mb-2">ข้อมูลของคุณ</div>
                <v-text-field
                  v-model="form.firstname"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="ชื่อ"
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="form.lastname"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="นามสกุล"
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="tel"
                  v-mask="'##########'"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="เบอร์โทรศัพท์"
                  :rules="rules.max10"
                />
              </v-col>
              <v-col cols="12" class="pb-0">
                <div class="mb-2">ที่อยู่สำหรับจัดส่ง</div>
                <v-text-field
                  v-model="form.address1"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="ที่อยู่"
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="form.address2"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="ที่อยู่"
                />
                <v-text-field
                  v-model="form.subdistrict"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="ตำบล/แขวง"
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="form.district"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="อำเภอ/เขต"
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="form.province"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="จังหวัด"
                  :rules="rules.required"
                />
                <v-text-field
                  v-model="postcode"
                  dense
                  outlined
                  hide-details="auto"
                  class="mb-4"
                  label="รหัสไปรษณีย์"
                  v-mask="'#####'"
                  :rules="rules.max5"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="my-4"></v-divider>
          <v-btn
            block
            large
            x-large
            dark
            class="font-weight-bold rounded-lg confirmbut"
            @click="redemption()"
          >
            ยืนยัน
          </v-btn>
          <v-dialog
            v-model="isConfirm2"
            persistent
            :overlay-opacity="0.9"
            max-width="375"
          >
            <v-card
              class="rounded-lg pa-4"
              max-height="329"
              height="329"
              max-width="375"
            >
              <div
                class="fn24-weight7 py-4 text-center justify-center"
                style="color: #07204b"
              >
                กำลังดำเนินการจัดส่ง !
              </div>
              <div class="py-0 px-4 my-4 fn14 text-center">
                เจ้าหน้าที่จะทำการจัดส่งของรางวัลภายใน 30 วันทำการ
                <br />
                ดูรายละเอียดประวัติการแลกของรางวัลของคุณได้ที่ “My Rewards”
              </div>
              <v-card-actions class="px-0">
                <v-spacer></v-spacer>
                <v-btn
                  block
                  large
                  outlined
                  x-large
                  dark
                  class="rounded-lg fn16-weight7 confirmbut"
                  @click="goBack()"
                >
                  กลับสู่หน้าหลัก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>

        <!-- <v-card>
          <v-img
            src="https://s3-alpha-sig.figma.com/img/bf77/5097/e9a573a7fea2d26ddeb0d88504ff7017?Expires=1648425600&Signature=fcf3vhSo4w43pBeMiupYUuzcnhokk~4SZZhdBXNABc9fMw~qks7fECSOab3USbXQvHpB0HVjGxP2YpoI2qUIrvJSsU06NUpD9vAtsW6UhXPrLk~UyyNMYOVaA9WmlfpFR3F1-wmf0oiV9g9x4jKpgefwcbpx1OgY41PkpOVN-FrTXc8uC4~Pc29xgQiDvghhOOb-fRM87IDCMq-degtxyjm6Yn8yZPAixVPHLvO5GnmWm7L2iSSqtSJejAbIz2rZjDKSWQN6Tw0osNalgau9MZCoOn3hl12Qd3WRU8DVsaFt5lUBrYoRvh3wag3bHBircZsHdeDN20Hksvv7fNLAFg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
          ></v-img>
          <v-row class="ma-4" dense no-gutters align="center" justify="center">
            <v-card-title>BIAH Event Evaluation</v-card-title>
            <v-card-subtitle style="color: grey" class="fn12"
              >Thank you for attending ‘ FLEXcitement 2021” Let’s do the
              questionnaire and collect 10 points
            </v-card-subtitle>
            <v-col cols="12">
              <v-divider class="mb-2"></v-divider>
              <span class="question">
                How did you hear about FLEXcitement?
              </span>
              <v-text-field
                v-model="form.question1"
                outlined
                dense
                hide-details="auto"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="question"> What session did you like best? </span>
              <v-text-field
                v-model="form.question2"
                outlined
                dense
                hide-details="auto"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="question">
                What session did you like the least?
              </span>
              <v-text-field
                v-model="form.question3"
                outlined
                dense
                hide-details="auto"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="question">
                What was your favourite thing about the event?
              </span>
              <v-text-field
                v-model="form.question4"
                outlined
                dense
                hide-details="auto"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="question">Do you have any other feedback?</span>
              <v-text-field
                v-model="form.question5"
                outlined
                dense
                hide-details="auto"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-divider class="mt-4 mb-2"></v-divider>
              <span class="question">Delivery Address</span>
              <v-text-field
                v-model="form.delevery_address"
                outlined
                dense
                hide-details="auto"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-2">
            <v-col cols="6">
              <v-btn
                width="120"
                height="57"
                outlined
                dark
                class="font-weight-bold rounded-lg fn16 cancelbut"
                @click="cancel()"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                width="120"
                height="57"
                outlined
                dark
                class="rounded-lg fn16-weight7 confirmbut"
                @click="redemption()"
              >
                ตกลง
              </v-btn>
            </v-col>
          </v-row>
        </v-card> -->
      </v-dialog>
      <v-dialog v-model="isConfirm3" persistent>
        <v-card class="my-6 rounded-lg">
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center" style="align-self: center">
              <br />
              <span
                style="
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  align-items: center;
                  text-align: center;
                  color: #1a347f;
                "
              >
                Thank you for
              </span>
              <br />
              <span
                style="
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  align-items: center;
                  text-align: center;
                  color: #1a347f;
                "
              >
                Feedback!
              </span>
            </v-col>
            <br />
            <v-col cols="10" class="text-center" style="align-self: center">
              <span
                style="
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 22px;
                  align-items: center;
                  text-align: center;
                  color: #1a347f;
                "
              >
                <img
                  src="@/assets/BI2.png"
                  height="50px"
                  width="50px"
                  align="left"
                  justify="left"
                  class="mx-2 mt-n1"
                />You earn 10 points</span
              >
            </v-col>
            <v-col cols="11">
              <span style="font-weight: 300; font-size: 12px">
                Our staff will review your activity and assign the points to
                your account within 24 hours.</span
              >
            </v-col>
            <v-col cols="11" class="text-right">
              <v-btn
                outlined
                width="120"
                height="57"
                class="rounded-lg fn16-weight7 confirmbut"
                block
                @click="goToHome()"
              >
                Back to Homepage
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  // async asyncData({ route }) {
  //   const { reward } = route.query;
  //   return {
  //     reward,
  //   };
  // },

  data() {
    return {
      tel: "",
      postcode: "",
      valid: false,
      dialog: false,
      isConfirm1: false,
      isConfirm2: false,
      isConfirm3: false,

      shortDesc: "",
      name: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        max10: [
          (v) => v.length == 10 || "หมายเลขโทรศัพท์ต้องมี 10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
        max5: [
          (v) => v.length == 5 || "รหัสไปรษณีย์ต้องมี 5 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      reward: "",

      form: {
        rewardId: 0,
        rewardImg: "",
        reward_name: "",
        description: "",
        point: 0,
        rewardCategoryId: 0,
        delevery_address: "",

        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        subdistrict: "",
        district: "",
        province: "",
        postcode: "",
        tel: "",
        // address1: (...)
        // address2: (...)
        // bi_current_point: (...)
        // ce_current_credit: (...)
        // createdAt: (...)
        // district: (...)
        // firstname: "หมอเต้ย"
        // id: (...)
        // lastname: (...)
        // lineid: (...)
        // medical_license_no: (...)
        // postcode: (...)
        // province: (...)
        // registered_date: (...)
        // royalty_status: (...)
        // species_type: (...)
        // status: (...)
        // subdistrict: (...)
        // type: (...)
        // updatedAt: (...)
        // username: (...)
        // usertype: (...)
        // vet_card_id: (...)
      },
      // firstname: "",
      // lastname: "",
      // tel: "",
      // address1: "",
      // address2: "",
      // subdistrict: "",
      // district: "",
      // province: "",
      // postcode: "",
      userData: [],
      isDetail: 0,
      status: "",
      tracking_number: "",
      canRedeem: false,
    };
  },
  async created() {
    // await this.getUser();
    await this.getProfile();

    const urlParams = this.$route.query;
    this.reward = urlParams.reward;
    this.isDetail = urlParams.isDetail;
    this.status = urlParams.status;
    this.tracking_number = urlParams.tracking;
  },
  watch: {
    isConfirm1(val) {
      if (val) {
        this.form.firstname = this.userData.firstname;
        this.form.lastname = this.userData.lastname;
        this.form.address1 = this.userData.address1;
        this.form.address2 = this.userData.address2;
        this.form.subdistrict = this.userData.subdistrict;
        this.form.district = this.userData.district;
        this.form.province = this.userData.province;
        // this.form.tel = this.userData.tel ? this.userData.tel : "";
        // this.form.postcode = this.userData.tel ? this.userData.postcode : "";

        console.log("singto", this.form);
      }
    },
  },

  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U1a2cf1f80834b4db16b1f93b7b63cab6`
      );
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
      this.tel = this.userData.tel ? this.userData.tel : "";
      this.postcode = this.userData.postcode ? this.userData.postcode : "";
      await this.getOneRewards();
    },
    async getOneRewards() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reward/${this.reward}
          `
      );
      this.form = response.data.data;
      console.log("this.form", response.data.data);
      // this.form.rewardId = response.data.data.rewardId
      console.log("form.point", this.form.point);
      console.log("userData.bi_current_point", this.userData.bi_current_point);
      if (this.userData.bi_current_point < this.form.point) {
        this.canRedeem = false;
      } else {
        this.canRedeem = true;
      }
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    goBack() {
      this.$router.push("ManageMyRewards");
    },
    cancel() {
      // this.$router.push("ManageRewards");
      this.dialog = false;
      this.isConfirm1 = false;
    },
    goToHome() {
      this.$router.push("/");
    },
    conditions() {
      this.dialog = true;
    },
    goToRedeemSuccess() {
      this.$router.push("RedeemSuccess");
    },
    async redemption() {
      if (this.$refs.form.validate(true)) {
        const auth = {
          headers: {
            // Authorization: `Bearer ` + this.userData.token,
            Authorization:
              `Bearer ` +
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NiwidXNlcm5hbWUiOiJQRU5DIFRFU1QiLCJwYXNzd29yZCI6IiQyYiQxMCRnRS5SRXBpTmJVdVZhNVBjZkRKSk9ldUNjNjVQVUgvdHZPeDdQOUR0cHlHdWR0bzh6VFBRNiIsImZpcnN0bmFtZSI6IlN1cGFzaXQiLCJsYXN0bmFtZSI6IkNodXdhdHRhbmEiLCJ0eXBlIjoiVmV0IiwibWVkaWNhbF9saWNlbnNlX25vIjoxMjM0NTYsInJlZ2lzdGVyZWRfZGF0ZSI6IjIwMjItMDEtMzBUMTI6MzE6NDUuNjcyWiIsInN0YXR1cyI6dHJ1ZSwiY3JlYXRlZEF0IjoiMjAyMi0wMS0zMFQxMjozMTo0NS42NzJaIiwidXBkYXRlZEF0IjoiMjAyMi0wMS0zMFQxMjozMTo0Ni4wMTVaIiwiaWF0IjoxNjQzODAzODQxfQ.arPNmo_EZGg0H8YAnMlqB6Fx5DkkDzdsd3e4xXYz7ag",
          },
        };
        this.form = {
          ...this.form,
          id: null,
          rewardId: this.form.id,
          redemption_name: this.form.reward_name,
          delevery_address: this.form.firstname + " " + this.form.lastname,
          status: "Waiting",
          tel: this.tel,
          postcode: this.postcode,
          userId: this.userData.id,
          createdAt: null,
          updatedAt: null,
        };
        console.log("this.form", this.form);
        // this.userData
        const response = await this.axios.post(
          // `${process.env.VUE_APP_API}/redemption?usertype=${this.userData.species_type}`,
          `${process.env.VUE_APP_API}/redemption`,
          this.form,
          auth
        );
        console.log("createCate", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          // this.$swal.fire({
          //   icon: "success",
          //   text: `สร้างใบประกอบวิชาชีพสำเร็จ`,
          //   showConfirmButton: false,
          //   timer: 1500,
          // });
          this.isConfirm1 = false;
          this.isConfirm2 = true;
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.confirmbut {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
.cancelbut {
  background: linear-gradient(268.1deg, #a5a5a5 7.54%, #9f9f9f 100.72%);
}
.question {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1a347f;
}
.donut {
  color: white;
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>